<template>
  <div class="user-profile-posts">
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="item.class"
      v-if="item.posts && item.posts.length"
    >
      <div class="text-h6 font-weight-bold mb-4">
        {{ item.title }}
      </div>

      <component
        :is="shouldUseSlider ? 'v-sheet' : 'div'"
        color="transparent"
        class="mx-auto"
      >
        <component
          :is="shouldUseSlider ? 'v-slide-group' : 'v-row'"
          :class="shouldUseSlider ? 'pb-6' : ''"
          :show-arrows="shouldUseSlider"
        >
          <component
            :is="shouldUseSlider ? 'v-slide-item' : 'v-col'"
            v-for="item in item.posts"
            :key="'mobile-post-' + item.post_id"
            :cols="12"
            :sm="6"
            lg="3"
          >
            <v-card
              flat
              class="box-shadow-soft user-profile-post-item"
              @click="redirectToWeb(item.link)"
              :class="{ 'mx-4 mb-6': shouldUseSlider }"
            >
              <v-img
                :src="proxyUrl(item.thumbnail)"
                crossorigin="anonymous"
                height="300"
                contain
              />

              <v-card-text>
                <div class="mb-2">
                  {{ dayjs(item.created).format("LLL") }}
                </div>

                <div v-if="item.text">
                  {{ item.text.slice(0, 99) }}...
                </div>
              </v-card-text>

              <v-card-actions class="px-5 py-3 post-card-stats font-weight-bold">
                <div class="d-flex">
                  <v-icon>thumb_up</v-icon> &nbsp;
                  {{ nFormatter(item.stat.likes) }}
                </div>

                <v-spacer></v-spacer>

                <template v-if="item.stat.views">
                  <div class="d-flex">
                    <v-icon>play_circle</v-icon> &nbsp;
                    {{ nFormatter(item.stat.views) }}
                  </div>

                  <v-spacer></v-spacer>
                </template>

                <div class="d-flex">
                  <v-icon>comment_bank</v-icon> &nbsp;
                  {{ nFormatter(item.stat.comments) }}
                </div>
              </v-card-actions>
            </v-card>
          </component>
        </component>
      </component>
    </div>
  </div>
</template>

<script>
// Import Vuetify components for dynamic view (using component:is)
import { VRow, VCol, VSheet, VSlideGroup, VSlideItem } from 'vuetify/lib'

// Export the SFC
export default {
  // Name of the component
  name: "Posts",

  // Accept incoming data from parent
  props: {
    // The influencer's platform
    platform: {
      type: String,
      required: true,
    },

    // The mapped user data object
    userData: {
      type: Object,
      required: true
    }
  },

  // Register the child components
  components: {
    VRow,
    VCol,
    VSheet,
    VSlideGroup,
    VSlideItem,
  },

  // Computable data variables
  computed: {
    /**
     * Whether or not to use the slider in view
     *
     * @returns {Boolean}
     */
    shouldUseSlider() {
      return this.isLargeDevice
    },

    /**
     * Section items for the view
     *
     * @returns {Array}
     */
    items() {
      const array = [
        {
          title: "Top Posts",
          class: "mb-4 mb-lg-0",
          posts: this.userData.data.user_profile.top_posts
        },
        {
          title: "Sponsored Posts",
          class: "mb-4 mb-lg-0",
          posts: this.userData.data.user_profile.commercial_posts
        }
      ]

      // Check if recent data is available
      // if (this.userData.data.extra.recent_posts && this.userData.data.extra.recent_posts.length) {
      //   array.push({
      //     title: "Recent Posts",
      //     class: null,
      //     posts: this.userData.data.extra.recent_posts.map((item) => {
      //       return {
      //         user_id: item.user.pk,
      //         username: item.user.username,
      //         user_picture: item.user.profile_pic_url,
      //         user_url: `https://instagram.com/${item.user.username}`,
      //         type: null,
      //         post_id: item.pk,
      //         created: item.taken_at,
      //         text: item.caption?.text || null,
      //         image: item.display_url,
      //         thumbnail: item.display_url,
      //         link: `https://instagram.com/p/${item.code}`,
      //         hashtags: [],
      //         stat: {
      //           likes_and_views_counts_enabled: !item.like_and_view_counts_disabled,
      //           likes_count_enabled: null,
      //           views_count_enabled: null,
      //           likes: item.like_count,
      //           comments: item.comment_count,
      //           views: item.play_count,
      //           plays: item.play_count
      //         }
      //       }
      //     })
      //   })
      // }

      // Return the array of posts
      return array
    }
  }
}
</script>
